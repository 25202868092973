.modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    max-width: 400px;
    width: 100%;
  }
  .modal p{
    color: var(--darkblue);
  }
  
  .modal-content {
    text-align: center;
  }
  
  .modal-buttons {
    margin-top: 16px;
    display: flex;
    justify-content: center;
  }
  
  .cancel-btn {
    background-color: #ccc;
    color: #333;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    margin-right: 8px;
    cursor: pointer;
  }
  
  .confirm-btn {
    background-color: #6d9e60;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
  }
  