@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

:root {
  
  --font-family: 'Poppins';

  /* --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
   --globalcolor:#1C092B;
  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
  --button-bg:#FF4820;
  --yellow: linear-gradient(180deg, #F8D49A -146.42%, #FAD79D -46.42%);
  --orange: #fca61f;
  --black: #242d49;
  --gray: #788097;
  --purple: linear-gradient(180deg, #BB67FF 0%, #C484F3 100%);
  --pink: #FF919D;
  --glass: rgba(255, 255, 255, 0.54);
  --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
  --activeItem: #f799a354; */
  --black:#000;
  --white:#ffffff;
  --lightgrey:#f1f1f1;
  --darkblue:#0d102c;
  --primarycolor:#3d51f2;
  --secondarycolor:#5670ef;
  --lightDark:#080A25;
  --darkText:#8388B4;
  --black:#000;
  --white:#ffffff;
  --lightgrey:#f1f1f1;
  --darkblue:#0d102c;
  --primarycolor:#3d51f2;
  --secondarycolor:#5670ef;
  --lightDark:#080A25;
  --darkText:#8388B4;
}