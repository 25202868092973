
  
  .choose {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #000511;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    z-index: 999;
    color: #000000;
    
  }
  .choose::before {
    animation-delay: 1s;
  }
  
  @keyframes loader {
    0% {
      transform: scale(1);
      opacity: 0;
    }
  
    50% {
      opacity: 1;
    }
  
    100% {
      transform: scale(0);
      opacity: 0;
    }
  }
  .choose .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .choose h1 {
    color: #fff;
    font-size: 25px;
    margin-bottom: 40px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .choose .payment-options {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .choose .payment-option {
    width: 220px;
    padding: 20px;
    margin: 0 20px;
    color: var(--darkblue);
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: fade-in 1s ease;
  }
  
  .choose .payment-option:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .choose .payment-option img {
    width: 120px;
    height: auto;
    margin-bottom: 10px;
  }
  .choose h2{
    font-size: 19px;
    color: var(--darkblue);
  }
  
  #selected-method {
    font-weight: bold;
    color: #fff;
    font-size: 18px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    animation: fade-in 1s ease;
  }
  
  @keyframes fade-in {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Media Queries */
  @media only screen and (max-width: 600px) {
    .choose .payment-options {
      flex-wrap: wrap;
      margin-bottom: 20px;
    }
  
    .choose .payment-option {
      margin: 10px;
    }
  }
  