.bot{
    position: relative;
    overflow: hidden;
}
.bot .navbar{
    background: var(--globalcolor);
}

.clickbot{

        background:#fff;
        background-size: cover;
        height: 100vh;
        overflow: hidden;
      
        
}
/* .clickbot::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #03217280;
  } */
  .botpart{
    position: absolute;
  }

.countdownclock p{
  font-size: 40px;
  font-weight: 700;
  margin-top: 2rem;
}
.digital-clock h4{
  font-size: 40px;
  font-weight: 700;
  margin-top: 2rem;

}
.digital-clock button{
   padding: 1rem 4rem;
   margin-top: 3rem;
   background-color: var(--globalcolor);
   color: #fff;
   font-weight: 700;
   border: none;
}
  
 
  
  /* .hours {
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  } */
  
  .clock {
    height: 20vh;
    color: var(--globalcolor);
    font-size: 22vh;
    font-family: sans-serif;
    line-height: 20.4vh;
    display: flex;
    position: relative;
    /*background: green;*/
    overflow: hidden;
   justify-content: center;
   margin-top: 2rem;
  }
  
  /* .clock::before, .clock::after {
    content: '';
    width: 7ch;
    height: 3vh;
    background: linear-gradient(to top, transparent, black);
    position: absolute;
    z-index: 2;
  }
  
  .clock::after {
    bottom: 0;
    background: linear-gradient(to bottom, transparent, black);
  } */
  
  .clock > div {
    display: flex;
  }
  
  .tick {
    line-height: 17vh;
  }
  
  .tick-hidden {
    opacity: 0;
  }
  
  .move {
    animation: move linear 1s infinite;
  }
  
  @keyframes move {
    from {
      transform: translateY(0vh);
    }
    to {
      transform: translateY(-20vh);
    }
  }
  
  @media(max-width:767px){

    .digital-clock h4{
      font-size:30px;
    }
    .clock{
      font-size: 10vh;
      line-height: 15vh;
    }
    
.countdownclock p{
  font-size: 30px;

}
  }