.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color:var(--darkblue) ;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;

}

.spinner {

  width: 250px;
  height: 150px;
  position: relative;
  animation: rotation 0.75s linear infinite;
  border-radius: 100em;
}

.path {
  stroke-dasharray: 100;
  stroke-dashoffset: 20;
  stroke-linecap: round;
}

@keyframes rotation {
  to {
    transform: rotate(360deg);
  }
}
