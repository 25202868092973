.container-fluid {
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #2a2a5a; /* Similar dark background color */
}

.table-container {
  overflow-x: auto;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.token-address {
  display: inline-block;
  cursor: pointer;
  color: #00acee; /* Optional: color to indicate interactivity */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 120px; /* Adjust based on the number of visible characters */
  transition: max-width 0.3s ease;
}

.token-address.expanded {
  max-width: 100%; /* Expand to full width */
  white-space: normal;
}

.crypto-table {
  width: 100%;
  min-width: 700px; /* Ensures a minimum width for readability on smaller screens */
  border-collapse: collapse;
  background-color: #3b3b74;
  color: #ffffff;
}

.crypto-table th,
.crypto-table td {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #565686;
}

.crypto-table th {
  background-color: #565686;
  font-weight: bold;
  color: #ffc107; /* Yellow for headers, similar to the provided image */
}

.crypto-table td {
  color: #ffffff;
  font-size: 14px;
}

.crypto-table tr:nth-child(even) {
  background-color: #4c4c8e;
}

.crypto-table td p {
  margin: 0;
}

.text-warning {
  color: #ffc107;
}

.view-desc {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  background-color: #3b3b74;
  color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  z-index: 100;
}

.desc-details img {
  max-width: 100px;
  border-radius: 4px;
}

.description p {
  margin: 5px 0;
  font-size: 14px;
  display: flex;
}

@media (max-width: 768px) {
  .crypto-table th, .crypto-table td {
      padding: 8px;
      font-size: 12px;
  }
}

@media (max-width: 480px) {
  .crypto-table {
      font-size: 10px;
  }

  .view-desc {
      width: 90%;
  }

  .crypto-table th, .crypto-table td {
      padding: 6px;
  }
}

@keyframes flicker {
  0% { opacity: 1; transform: scale(1); color: #00ff00; }
  50% { opacity: 0.8; transform: scale(1.1); color: #ff4500; }
  100% { opacity: 1; transform: scale(1); color: #00ff00; }
}

.flicker {
  animation: flicker 0.5s ease-in-out infinite alternate;
}
