  .box{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--globalcolor);
    
  }

.card5 {
    
    width: 80%;
    height: 350px;
    background-color: rgb(255, 255, 255);
    border-radius: 7px;
    box-shadow: 4px 4px 4px 4px rgba(0.3, 0.3, 0.3, 0.3);
    position: relative;
    overflow: hidden;
  }
  
.card5-content h2{
    font-size: 30px;
    font-weight: 800;
    margin-top: 2rem;
}
 .card5-content span{
    color: rgb(4, 156, 55);
    cursor: pointer;
    font-weight: 700;
    font-size: 17px;
 }
 .card5-content p{
    font-size:20px;
    margin-top: 2rem;
 }
  .number{
    border: 2px solid rgb(255, 255, 255);
    border-radius: 50%;
    font-size: 20px;
    color: #fff !important;
    padding: 5px;
    background-color: rgb(4, 156, 55);
  }


  @media screen and (max-width: 1200px) {
    
  
}

 @media screen and (max-width: 768px) {
    .card5-content h2{
        font-size: 25px;
        
    }
    .card5{
      width: 95%;
    }
    .card5-content p{
        font-family: var(--font-family);
        font-size:16px;
        margin-top: 2rem;
     }
     .number{
        border: 2px solid rgb(255, 255, 255);
        border-radius: 50%;
        font-size: 12px;
        color: #fff !important;
        padding: 5px;
        background-color: rgb(4, 156, 55);
      }
    
  }
 
  
 