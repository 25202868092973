.admenuitems{
 
    position:absolute;
    left: 0;
    top: 0;
    background-color: #fff;
    min-width: 250px;
    padding-bottom: 1rem;
    min-height: 100vh;
    z-index:1000;
    
}
.overalladmenu{
    position:fixed;
    top: 0;
    left:0;
    right: 0;
    bottom: 0;
    min-height: 100vh;
    min-width: 100vw;
    background-color: #0000001e;
    overflow-y: auto;
    z-index:1000;
    padding-bottom: 1rem;
}

.aditem{
    margin-top: 1rem;
     text-align: start;
     margin-right: 1rem;
     

}
.admenuitem{
    display: flex;
    font-size:16px;
    color: #6b6b6b;
    padding-left: 1rem;
    align-items: center;
    margin-top: 2rem;
    max-height: 300px;
    cursor: pointer;


}
.closemenu{
 color:black;
}

.admenuitem p{
    margin-left: 0.9rem;
    margin-right: 0.4rem;
}
.addropdown{
    display:flex;
    flex-direction: column;
    align-items: start;
    margin-left: 3rem;
      
}
.addropdown p{
    margin-top: 1rem;
    color: #6b6b6b;
    cursor: pointer;
    
}
.admenuuser{
    margin-top: 1rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid rgb(219, 219, 219);
    padding-bottom:0rem;
    width: 100%;
}
.admenuuser p{
    text-align: start;
    margin-left: 1rem;
    font-weight: 700;
    font-size: 25px;
    color:#000;

}
.admenuuser div{
    background-color: var(--darkblue);
    padding: 0.5rem 2rem;
    margin: 1rem 0.7rem 2rem 0.7rem;
    font-weight: 700;

}
.topadmenu{
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 1rem 2rem;
    cursor: pointer;
}
.dashpayment{
    display: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

.slideInLeft {
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    }
    @-webkit-keyframes slideInLeft {
    0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    visibility: visible;
    }
    100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    }
    }
    @keyframes slideInLeft {
    0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    visibility: visible;
    }
    100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    }
    } 
    .zoomIn {
        -webkit-animation-name: zoomIn;
        animation-name: zoomIn;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        }
        @-webkit-keyframes zoomIn {
        0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
        }
        50% {
        opacity: 1;
        }
        }
        @keyframes zoomIn {
        0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
        }
        50% {
        opacity: 1;
        }
        } 
        
        .slideOutLeft {
            -webkit-animation-name: slideOutLeft;
            animation-name: slideOutLeft;
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            }
            @-webkit-keyframes slideOutLeft {
            0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            }
            100% {
            visibility: hidden;
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
            }
            }
            @keyframes slideOutLeft {
            0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            }
            100% {
            visibility: hidden;
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
            }
            } 
