.row-2{
  display: flex;
  align-items: center;
  justify-content: center;
  margin:1rem 3rem;

}
.rows{
  display: flex;
 align-items: center;
 justify-content: space-around;
 padding-bottom: 2rem;
 
}
ul {
    margin: 10px;
    padding: 0px;
}
/* .footer-section {
  padding-top: 3rem;
  background: #21186B;
  position: relative;

} */
.footer-cta {
  border-bottom: 1px solid #373636;
}

.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #757575;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
  margin-bottom: 30px;
}
.footer-text{
  margin-top: 1rem;
 font-size: 14px;
 text-align: justify;
}
.footer-logo img {
    max-width: 200px;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
      color: #7e7e7e;
  line-height: 28px;
}
.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}
.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}
.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}
.facebook-bg{
  background: #3B5998;
}
.twitter-bg{
  background: #55ACEE;
}
.google-bg{
  background: #DD4B39;
}
.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}

.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 50%;
  margin-bottom: 12px;
}

.footer-widget ul li a {
  color: #ffffff;
  text-transform: capitalize;
}

.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area{
  background: #21186B;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #ffffff;
}
.copyright-text p a{
  color: #ffffff;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a{
  color: #ff5e14;
}
.footer-menu li a {
  font-size: 14px;
  color: #ffffff;
}
.row-3{
  display: flex;
  align-items: center;
  justify-content: center;
}


.footer {
  display: flex;
  flex-flow: row wrap;
  color: #000000;
  padding: 2rem 1rem;
  background-color: #fff;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.footer > * {
  flex:  1 100%;
}

.footer__addr {
  margin-right: 1.25em;
  margin-bottom: 2em;
  
}
.footer__addr h2{
  color: #000000;
  font-weight: 700;
  font-size: 18px;
}

.footer__logo {
font-family: var(--font-family);
  font-weight: 800;
  
  font-size: 2.5rem;
}

.footer__addr h2 {
  margin-top: 1.3rem;
  margin-bottom: 2rem;
  font-size: 15px;
  font-weight: 700;
}

.nav__title {
  color: #000000;
  font-weight: 700;
  font-size: 18px;

}

.footer address {
  font-style: normal;
  color: #000000;
}

.footer__btn {
  height: 36px;
  max-width: max-content;
  color: #000000;
  line-height: 2;
  margin: 0.6em 0;
  font-size: 1rem;
}

.footer ul {
  list-style: none;
  padding-left: 0;
  text-align: start;
}

.footer li {
  line-height: 2em;
}

.footer a {
  text-decoration: none;
}

.footer__nav {
  display: flex;
  justify-content: space-between;
	flex-flow: row wrap;
}
.nav__ul li{
  margin: 1rem 0;
}
.nav__item{
  margin-top: 1rem;
}
.footer__addr img{
  width:350px;
}

.footer__nav > * {
  flex: 1 50%;
  margin-right: 1.25em;
}

.nav__ul a {
  color: #000000;
}

.nav__ul--extra {
  column-gap: 1.25em;
}

.legal {
  display: flex;
  flex-wrap: wrap;
  color: #999;
}
  
.legal__links {
  display: flex;
  align-items: center;
}

.heart {
  color: #2f2f2f;
}

.firstfootermenu{
  display: flex;
  justify-content: space-around;
}

@media screen and (min-width: 24.375em) {
  .legal .legal__links {
    margin-left: auto;
  }
}

@media screen and (min-width: 40.375em) {
  .footer__nav > * {
    flex: 1;
  }
  
  /* .nav__item--extra {
    flex-grow: 2;
  } */
  
  .footer__addr {
    flex: 1 0px;
  }
  
  .footer__nav {
    flex: 2 0px;
  }
}
