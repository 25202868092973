@import url('https://fonts.crypto-googleapis.crypto-com/css2?family=Poppins:wght@400;500;600;700&display=swap');
:root{
    --black:#000;
    --white:#ffffff;
    --lightgrey:#f1f1f1;
    --darkblue:#0d102c;
    --primarycolor:#3d51f2;
    --secondarycolor:#5670ef;
    --lightDark:#080A25;
    --darkText:#8388B4;
 }
 
/* common styles */
section{
    width: 95%;
    max-width: 1200px;
    margin: auto;
    margin-top: 2em;
}

.crypto-gridSection{
    display: grid;
    align-items: center;
    gap: 40px;
    margin-bottom: 5em;
}

#sectionPic{
    width: 100%;
    max-width: 550px;
    margin: auto;
}

.crypto-sectionPic img{
    width: 100%;
}

.crypto p{
    color: var(--lightgrey);
}
.crypto .crypto-carouselPara{
    color: #000;
}

.crypto-darkPara{
    color: var(--darkText);
    font-weight: 500;
}

.crypto-btn{
    border: 1px solid #fff;
    border-radius: 50px;
    background-color: transparent;
    color: var(--white);
    font-size: 1.1rem;
    padding: .7em 1.5em;
    cursor: pointer;
}

.crypto-btn1{
    margin-right: 1em;
}

.crypto-btn2{
    border: none;
}

.crypto-primaryBtn{
    background-color: var(--primarycolor);
}

@keyframes bounce {
    0% {transform:translateY(-30px);}
    50% {transform:translateY(30px);}
    100% { transform:translateY(-30px)}
  }

.crypto-bouncepic img{
   animation: bounce 15s infinite;    
}

.crypto-sectionHeader{
    color: var(--white);
    font-size: 2rem;
    margin-bottom: .7em;
}

.crypto-sectionPara{
    font-size: 1.1rem;
    margin-bottom: 3em;
}



@media screen and (min-width:800px) {
    section{
        width: 85%;
    }   
    .mobile-menu{
        display: block;   
        
    }
    .crypto-gridSection{
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (min-width:1000px){
    .crypto-sectionHeader{
        font-size: 3em;
    }
    .mobile-menu{
        display: block;
         
        
    }
}