.hero-text{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.hero-text p{
    color: #fff;
    font-size: 15px;
    margin-bottom: 1rem;
    font-weight: 700;

}
.hero-text h1{
    color: #fff;
    font-family:Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 50px;
     word-spacing: 0.2rem;
}
.hero-text span{
    color: #fff;
    margin-top: 1rem;
    margin-bottom: 2rem;


}
.hero-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.hero-buttons button{
    background-color: #fff;
    border: none;
    color: var(--globalcolor);
    font-weight: 650 !important;
    border-radius: 10px;
    padding: 1.5rem 3rem ;
    margin-right: 2rem;
    font-family:ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 400;
}

@media (max-width:768px){
    .hero-text h1{
        font-size: 30px;
        font-family:ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

    
    }
    .hero-text span{
        font-size: 16px;
    }

}