/* @import url('https://fonts.crypto-googleapis.crypto-com/css2?family=Poppins:wght@400;500;600;700&display=swap'); */
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
}

:root{
   --black:#000;
   --white:#ffffff;
   --lightgrey:#f1f1f1;
   --darkblue:#0d102c;
   --primarycolor:#3d51f2;
   --secondarycolor:#5670ef;
   --lightDark:#080A25;
   --darkText:#8388B4;
}


.instruction .crypto-offerDesc{
    margin-top: 3rem;
   display: flex !important;
   flex-direction: column;
   justify-content: start !important;
   align-items: start;
   margin-left: 2rem;
}
.instruction .crypto-offerDesc p{
   margin-bottom: 3rem;
   text-align: start;
}
/* Header */
header{
    width: 100%;
    display: flex;
}

#barContainer{
    color: var(--white);
    font-size: 1.5rem;
    cursor: pointer;
}
.crypto{
    color: var(--white);
}
body{
    font-family: 'Poppins', sans-serif;
    background-color: var(--darkblue) !important;
    
  }

.crypto-nav{
    list-style-type: none;
    color: var(--white);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: var(--lightDark);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;
    z-index: 2;
}

.crypto-showNav{
    padding: 1em;
    padding-top: 5em;
    width: 70%;
    max-width: 300px;

}

.crypto-navLogo{
   margin-bottom: 3rem;
}

.crypto-nav-link{
    font-size: 1rem;
    width: 100%;
    font-weight: 500;
    margin-bottom: 1.crypto-5rem;
    text-transform: uppercase;
    cursor: pointer;
    white-space: nowrap;
}

.crypto-nav-link a{
    color: var(--white);
}

.crypto-sociallinkContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
}

.crypto-sociallink{
    margin-right: 1em;
    cursor: pointer;
}

@media screen and (min-width:800px) {
    .crypto-nav{
        position: relative;
        width: auto;
        background-color: transparent;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-right: 5%;
    }
    .crypto-nav-link{
          margin-right: 2em;
    }
    .crypto-navLogo,#barContainer,.crypto-sociallinkContainer{
        display: none;
    }
}
.crypto-header svg{
    color:#000000 ;
}
.crypto-header header{
    width: 100%;
    background-color: white;
}
/* Hero page */
.crypto-headline{
    color: var(--white);
    font-size: 3rem;
    width: 100%;
}

.crypto-cryptoText{
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #fff;
}

.crypto-btnContainer{
    margin-top: 2em;
}

.crypto-fa-play{
    margin-left: .5em;
}

.crypto-sectionPic{
    order: 0;
}

.crypto-sectionDesc{
    order: 1;
    margin-top: 1em;
}

@media screen and (min-width:800px) {
    .crypto-sectionPic{
        order: 1;
    }
    
    .crypto-sectionDesc{
        order: 0;
    }  
}
@media screen and (min-width:1000px){
    .crypto-headline{
        font-size: 4em;
    }
}

/* carousel */
.crypto-carouselContainer{
    display: grid;
    gap: 10px;
    grid-auto-flow: column;
    grid-auto-columns: 100%;
    overflow: hidden;
    border-radius: 20px;
    scroll-behavior: smooth;
}


.crypto-eachCarousel{
    background-color: var(--lightgrey);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1em;
    border-radius: 20px;
    margin: auto;
    width: 100%;
    height: 300px;
}
.crypto-carouselDesc{
    margin-left: 1em;
}

.crypto-carouselTitle{
    color: var(--darkblue);
    font-size: 1.crypto-5rem;
    margin-bottom: 1em;
}

.crypto-carouselPara{
    color: var(--lightDark);
    font-weight: 500;
    margin-bottom: 1em;
}
.crypto-carouselPara p{
    color: var(--lightDark);
    font-weight: 500;
    margin-bottom: 1em;
}


.crypto-carouselPrice{
    color: var(--darkblue);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    margin-bottom: 1em;
}

.crypto-carouselDiscount{
    color: var(--primarycolor);
}

.crypto-rect{
    width: 10px;
    height: 10px;
    background-color: var(--lightDark);
}

.crypto-carouselBtn{
    background-color: var(--secondarycolor);
    margin-top: 20px;
    text-transform: uppercase;
}

.crypto-carouselIndicator{
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}

.crypto-indicator{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--lightgrey);
    cursor: pointer;
    margin-right: 10px;
    opacity: .5;
}

.crypto-activeIndicator{
   opacity: 1;
}

@media screen and (min-width:700px) {
    .crypto-carouselContainer{
        grid-auto-columns: calc(100%/2);
    }

    .crypto-eachCarouselBorder{
        border: 5px solid var(--primarycolor) !important;
    }
    
}

@media screen and (min-width:1100px) {
    .crypto-carouselContainer{
        grid-auto-columns: calc(100%/3);
    }

}

/* Processes */
.crypto-processessDesc{
    order: 0;
}

.crypto-processesPic{
  order: 1;
}

.crypto-eachProcesses{
    display: flex;
    margin-bottom: 2em;
}

.crypto-eachProcesses img{
    margin-right: 2em;
}

.crypto-processTitle{
    color: var(--white);
    margin-bottom: 1em;
}

/* Markets */

.crypto-marketDesc{
    order: 0;
}

.crypto-eachMarket{
    display: flex;
    margin-bottom: 2em;
}

.crypto-eachMarket img{
    margin-right: 2em;
}

.crypto-marketTitle{
    color: var(--white);
    margin-bottom: 1em;
}

.crypto-marketspicSection{
    background-color: var(--lightgrey);
    border-radius: 20px;
    padding: 1em;
}

.crypto-marketspicHeader{
    color: var(--darkblue);
    text-align: center;
    padding: 1em;
    text-transform: uppercase;
}

.crypto-marketsPicContainer{
    width: 100%;
    max-width: 400px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding: 1em;
    margin: auto;
}

.crypto-marketPic{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 150px;
    border-radius: 20px;
}

.crypto-marketPic1{
    background-color: #FC9531;
}

.crypto-marketPic2{
  background-color: #4990FF;
}

.crypto-marketPic3{
  background-color: #3EC2F1;
}

.crypto-marketPic4{
   background-color: #50D79F;
}

.crypto-marketPic img{
    width: 50px;
}

.crypto-marketTitle{
  color: var(--white);
  font-weight: bold !important;
  margin-top: .5em !important;
  text-transform: uppercase !important;
}

/* Dashboard */
.crypto-dashboardDesc{
    order: 0;
}

.crypto-dashboardPic{
    order: 1;
    margin-top: 2em;
}

@media screen and (min-width:800px) {
    .crypto-dashboardDesc{
        order: 1;
    }
    
    .crypto-dashboardPic{
        order: 0;
    }
}


/* Funds */
.crypto-fundSection{
    width: 90%;
    max-width: 1100px;
    margin: auto;
    margin-top: 5em;
    margin-bottom: 5em;


}
.crypto-fundsContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}
.crypto-fund{
    margin-top: 3em;
}

.crypto-fundType{
    color: var(--white);
    margin-bottom: 1em;
    font-size: 1.crypto-5rem;
}

@media screen and (min-width:800px) {
    .crypto-fundSection{
        width: 85%;
    }    
}

@media screen and (min-width:1000px) {
    .crypto-fundsContainer{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .crypto-fund{
        margin-top: 1em;
    }
}

/* Newsletter */

.crypto-newsletter{
    border: 1px solid #fff;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    padding: 0;
    overflow: hidden;
}

.crypto-newsletter input{
    font-size: 1.1rem;
    padding: .7em 1.5em;
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
}

.crypto-newsletter input::placeholder{
    color: var(--lightgrey);
}

.crypto-newsletter button{
    background-color: var(--primarycolor);
    font-weight: 700;
}

.crypto-newsletterDesc{
    order: 0;
}

.crypto-newsletterPic{
    order: 1;
}

@media screen and (min-width:800px) {
    .crypto-newsletterDesc{
        order: 1;
    }
    
    .crypto-newsletterPic{
        order: 0;
    }
}

/* Join Now */
footer{
    background-color: var(--lightDark);
    margin-top: 5em;
}

.crypto-joinSection,.crypto-footerlinksContainer{
    width: 90%;
    max-width: 1100px;
    margin: auto;
    padding: 1em;
}

.crypto-joinSection{
    display: flex;
    align-items: center;
    padding: 3em 0em;
}

.crypto-joinSection .crypto-joinDesc{
    width: 80%;
}

.crypto-joinSection button{
    height: auto;
}



.crypto-footerlinksContainer{
    width: 90%;
    max-width: 1100px;
    margin: auto;
    padding: 1em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    justify-content: space-between;
    padding-top: 5em;
    border-top: 1px solid var(--lightgrey);
}

.crypto-footersociallinkContainer{
    margin-top: 1em;
}

.crypto-footerAboutus .crypto-sociallinkContainer{
    justify-content: flex-start;
}
.crypto-footerAboutus img{
    height: 100px;
    margin-top: -2rem;
}
.crypto-footerlink{
    display: flex;
    flex-direction: column;
    color: var(--white);
}

.crypto-linkTitle{
    font-size: 1.5rem;
    margin-bottom: .5em;
}

.crypto-footerlink a{
    color: var(--white);
    margin-bottom: 1em;
    transition: all .5s ease-in-out;
}

.crypto-footerlink a:hover{
    transform: translateX(10px);
}

.crypto-footerCopyright{
    background-color: var(--darkblue);
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
}

.crypto-footerCopyright p{
    text-align: center;
}

.crypto-developedBy{
    font-weight: bold;
    color: var(--white);
}

@media screen and (min-width:700px) {
    .crypto-footerlinksContainer{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}


/* Contact page */
.crypto-contactSection{
    margin-top: 2em;
}
.crypto-contactformContainer{
    display: flex;
    flex-direction: column;
    order: 1;
}

.crypto-contactPic{
    order: 0;
    width: 150px;
    background-color: rgba(241, 241, 241,.05);
    border-top-left-radius: 20%;
    
}

.crypto-contactPic img{
    width: 100%;
}

.crypto-contactForm div{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.crypto-contactInput{
    width: 100%;
    font-size: 1.1rem;
    padding: 1em;
    margin-bottom: 1em;
    border-radius: 20px;
    border: none;
    outline: none;
    border: 2px solid var(--white);
    background-color: transparent;
    resize: none;
    color: var(--white);
}

.crypto-contactInput::placeholder{
    color: var(--lightgrey);
    text-transform: capitalize;
}

.crypto-contactBtn{
    width: 200px;
}

.crypto-addressHeader{
    text-align: center;
    color: var(--white);
    margin-bottom: 2em;
}

.crypto-address{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  justify-content: space-between;
  width: 90%;
  max-width: 1100px;
  margin: auto;
}

.crypto-eachAddress h1{
    color: var(--white);
    margin-bottom: .5em;
    font-size: 1.5rem;
}

.crypto-eachAddress p{
    margin-bottom: 1em;
}

@media screen and (min-width:800px) {
    .crypto-contactformContainer{
        order: 0;
    }
    
    .crypto-contactPic{
        order: 1;
    }

    .crypto-address{
        grid-template-columns: 1fr 1fr 1fr;
    }
}

/* About Us */
.crypto-aboutusDesc{
    order: 0;
}

.crypto-statusContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    width: 90%;
    max-width: 1100px;
    margin: auto;
}

.crypto-status h1{
    color: var(--white);
    font-size: 1.crypto-5rem;
    font-weight: lighter;
}

.crypto-status h1 b{
    font-weight: bold;
    font-size: 2rem;
}

/* offer */
.crypto-services{
    width: 90%;
    max-width: 1100px;
    margin: auto;
    margin-top: 4em;
}

.crypto-aboutusimgContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.crypto-aboutusimgContainer img{
    width: 100%;
    border-radius: 10px;
}


.crypto-offers{
    margin-top: 5em;
    display: grid;
    gap: 50px;
}

.crypto-eachOffer{
   display: flex;
   align-items: flex-start;
}

.crypto-eachOffer img{
    margin-right: 1em;
}

.crypto-offerDesc h1{
    margin-bottom: .5em;
    font-size: 1.5rem;
}

/* video */

.crypto-videoSection{
  display: grid;
}

.crypto-videoSectionHeader{
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: .5em;
    font-weight: bold;
}

.crypto-video{
    position: relative;
    margin-bottom: 1em;
    border-radius: 20px;
    overflow: hidden;
}

.crypto-thumbnail{
    width: 100%;
}

.crypto-playIcon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    cursor: pointer;
}

/* Team */

.crypto-teamSection{
    margin-top: 5em;
}

.crypto-eachTeam{
    background-color: transparent;
    padding: 0;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.crypto-teamPic{
    position: relative;
    width: 80%;
    margin: auto;
}

.crypto-eachTeam img{
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.crypto-teamSocialLink{
    position: absolute;
    left: 50%;
    bottom: -25%;
    transform: translate(-50%);
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .5s ease-in-out;
}

.crypto-teamSocialLink img{
    width: 20px;
    margin-left: 1em;
}

.crypto-teamName{
    white-space: collapse;
}

.crypto-eachTeam:hover .crypto-teamSocialLink{
    bottom: 10%;
}


@media screen and (min-width:800px) {
    .crypto-aboutusDesc{
        order: 1;
    }   

    .crypto-statusContainer{
        gap: 50px;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .crypto-offers{
        grid-template-columns: 1fr 1fr;
       gap: 20px;
    }

    .crypto-videoSection{
        grid-template-columns: 1fr 3fr;
      }

    .crypto-videoSectionHeader{
        z-index: 1;
        transform: translateX(50%);
    }
      
}

@media screen and (min-width:1000px) {
    .crypto-offers{
        grid-template-columns: 1fr 1fr 1fr;
    }

    .crypto-videoSectionHeader{
        font-size: 3.5rem;
    }
}