.createproduct{
    margin: 0;
    padding: 0;
    /* background-color: rgba(16, 4, 184, 0.349); */
}
.createproduct .navbar{
  background-color: var(--globalcolor);

}
.forml {
    background-color: #fff;
    box-shadow: 0 10px 60px rgb(218, 229, 255);
    border: 1px solid rgb(159, 159, 160);
    border-radius: 20px;
    padding: 2rem .7rem .7rem .7rem;
    text-align: center;
    font-size: 1.125rem;
    max-width: 320px;
  }
  .product-form{
    display: flex;
    flex-direction: column;
    margin:6rem 7rem;
  }
  .product-form input{
    /* padding: 20px 30px; */
    border-radius: 10px;
    margin: 2rem 0;
    height: 50px;

  }
  .product-form button{
    display: inline-block;
   background-color: var(--globalcolor);
   color: #fff;
   border: none;
    height: 3rem;
    margin-top: 2rem;
    cursor: pointer;
  }
  
  .forml-title {
    color: #000000;
    font-size:22px;
    font-weight: 500;
  }
  
  .form-paragraph {
    margin-top: 10px;
    font-size: 0.9375rem;
    color: rgb(105, 105, 105);
  }
  
  .drop-container {
    background-color: #fff;
    position: relative;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-top: 2.1875rem;
    border-radius: 10px;
    border: 2px dashed rgb(171, 202, 255);
    color: #444;
    cursor: pointer;
    transition: background .2s ease-in-out, border .2s ease-in-out;
  }
  
  .drop-container:hover {
    background: rgba(0, 140, 255, 0.164);
    border-color: rgba(17, 17, 17, 0.616);
  }
  
  .drop-container:hover .drop-title {
    color: #222;
  }
  
  .drop-title {
    color: #444;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    transition: color .2s ease-in-out;
  }
  
  #file-input {
    width: 350px;
    max-width: 100%;
    color: #444;
    padding: 2px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid rgba(8, 8, 8, 0.288);
  }
  
  #file-input::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #084cdf;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
  }
  
  #file-input::file-selector-button:hover {
    background: #0d45a5;
  }
  .heading{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    font-size: 30px;
  }
 .product-form .description input{
    width: 100%;
    height: 120px;
    padding: 0 5px;
    word-wrap: break-word;
    resize: none;
    overflow: auto;
    white-space: pre-wrap;
 }
 /* @media screen and (max-width: 1200px) {
  
  }
   */
  @media screen and (max-width: 768px) {
    .product-form input{
      width: 100%;
    
      }
      .product-form{
        display: flex;
        flex-direction: column;
        margin:1rem 2rem;
      }
      .product-form .description input{
        width: 100%;
        height: 100px;
        /* padding: 100px; */
     }
     .heading{
        font-size: 20px;
     }
   
       
    }
   